<template>
    <div>
        <div class="card" style="background: white; padding: 20px">
            <div class="card-body p-0">
                <ul class="nav nav-fill">
                    <li class="nav-item" v-for="(value, index) in tabs" :key="value.name">
                        <a
                            class="nav-link"
                            :class="[{ active: tabs[currentTab].name === value.name }]"
                            @click="currentTab = index"
                        >
                            {{ value.name }}
                        </a>
                    </li>
                </ul>

                <KeepAlive class="mt-0">
                    <Component :is="tabs[currentTab].comp" />
                </KeepAlive>
            </div>
        </div>
    </div>
</template>

<script>
import { InviteCandidates, ProcessCandidates } from './components'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'Candidates',

    components: {
        InviteCandidates,
        ProcessCandidates,
    },

    computed: {
        iconOpenRoles: () => faFolderOpen,
        iconCandidates: () => faUsers,
        iconViews: () => faEye,
    },

    data() {
        return {
            featuredCandidates: [
                {
                    name: 'Jerry M.',
                    joined: '13 aug 2018',
                    phoneNumber: '251-661-5362',
                    location: 'New York',
                },
                {
                    name: 'Elianora V.',
                    joined: '13 aug 2018',
                    phoneNumber: '251-661-5362',
                    location: 'Ontario',
                },
                {
                    name: 'Marcos A.',
                    joined: '13 aug 2018',
                    phoneNumber: '251-661-5362',
                    location: 'Milan',
                },
                {
                    name: 'Alvis D.',
                    joined: '13 aug 2018',
                    phoneNumber: '251-661-5362',
                    location: 'Las Vegas',
                },
                {
                    name: 'Lissa S.',
                    joined: '13 aug 2018',
                    phoneNumber: '251-661-5362',
                    location: 'San Francisco',
                },
            ],

            currentTab: 0,
            tabs: [
                {
                    name: 'Recruit Candidates',
                    comp: 'InviteCandidates',
                    access: `['recruiter','company_admin']`,
                },
                {
                    name: 'Active Recruitment/Candidates',
                    comp: 'ProcessCandidates',
                    access: `['recruiter','company_admin']`,
                },
            ],
        }
    },
}
</script>
<style lang="sass" scoped>
a
  color: inherit
  cursor: pointer
.nav-link.active
  border-bottom: 4px solid #28a745
</style>
