<template lang="pug">
  div(style="padding: 20px; background: white")
    //- h4 Select job:
    multiselect(
      v-model="selectedJob",
      track-by="id",
      label="id",
      :custom-label="nameWithId"
      placeholder="Select or search for job",
      :options="options",
      :searchable="true"
    )
    h4.mt-2 Active recruitment
    b-table(
      :items="candidates"
      :fields="fields"
      empty-text="There is currently no active recruitment taking place"
      show-empty
    )
      template(slot="updated_at" slot-scope="data")
        p(v-if="data.item.updated_at >= 2") {{data.item.updated_at}} days ago
        p(v-else-if="data.item.updated_at === 1") {{data.item.updated_at}} day ago
        p(v-else-if="data.item.updated_at === 0") Today
      
      template(slot="name" slot-scope="data")
        p {{data.item.firstName}} {{data.item.lastName}}
      
      template(slot="source" slot-scope="data")
        p {{data.item.invited_by === undefined ? 'Applied' : 'Invited'}}
      
      template(slot="status" slot-scope="data")
        p {{data.item.accepted === null ? 'Pending' : data.item.accepted ? 'Accepted' : 'Rejected'}}
      
      template(slot="action" slot-scope="row")
        b-button(v-if="row.item.accepted" 
          variant="success" 
          size="sm"
          ) Schedule interview
        b-button(v-else-if="row.item.invited_by === undefined" 
          variant="outline-success" 
          @click="row.toggleDetails"
          size="sm"
          ) Review
        

      template(slot="row-details" slot-scope="data")
        h4(style="color: #42bff4") Skills
        p {{data.item.skills}}
        hr
        h4(style="color: #42bff4") Experience
        .row.m-0.mb-3(v-for="(exp, index) in data.item.exp", :key="exp.name" style="background: white")
          .col-5.pl-0
            p.m-0(style="font-size: 14px;color: #9b9b9b") {{exp.start}} - {{exp.currentJob ? 'present' : exp.end}}
            p.m-0(style="font-size: 20px; font-weight: 600") {{exp.name}}
            p.m-0(style="font-size: 18px; font-weight: 300") {{exp.role}}
          .col-6.pr-0
            p(style="font-size: 16px; color: #5d5d5d") {{exp.responsibilites}}

        b-button(variant="success") Accept
        b-button.ml-2(variant="outline-danger") Decline

</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect,
    },

    created() {
        this.fetchJobsList()
    },

    watch: {
        selectedJob(val) {
            if (val) {
                this.$store.state.backend
                    .get(`/recruiter/invited/${val.id}`)
                    .then((res) => (this.candidates = res.data))
                    .catch((err) => alert(err))
            } else {
                this.candidates = []
            }
        },
    },

    data() {
        return {
            selectedJob: '',
            options: [],
            candidates: [],
            fields: [
                'name',
                { key: 'updated_at', label: 'Last update' },
                'source',
                'status',
                'action',
            ],
        }
    },

    methods: {
        fetchJobsList() {
            this.$store.state.backend
                .get(`/recruiter/jobs`)
                .then((res) => (this.options = res.data))
                .catch((err) => alert(err))
        },

        nameWithId(obj) {
            return `${obj.name} / ${obj.title}`
        },
    },
}
</script>
