<template lang="pug">
  b-table(
    :items="sortedCandidates"
    :busy="table.busy"
    :fields="fields"
    empty-text="No candidates found"
    show-empty
    )
    template(v-slot:cell(name)="row") 
      //- in case someone has two first names
      p {{row.item.firstName.split(' ').length > 1 ? row.item.firstName.split(' ')[0] : row.item.firstName}} {{row.item.lastName}}.
    
    template(v-slot:row-details="data")
      .row
        .col-6
          h4(style="color: #42bff4") Skills

          span(
            v-for="skill in data.item.skills"
            style="color: #42bff4; border: 1px solid #42bff4; padding: 3px 10px; margin: 0 5px 5px 0; border-radius: 8px; display: inline-block; white-space: no-wrap"
          ) {{ skill }}

          div(v-if="!isInvited(data.item.invite_id, data.item.invite_jobs_id)",)
            //- button.btn.btn-success.btn-sm(
              style="display: inline-block"
              :disabled='data.item.invite_id !== null && data.item.invite_jobs_id === id'
              @click="inviteCandidate(data.item.id)"
              ) 
              font-awesome-icon.mr-1(
                v-if="isInvited(data.item.invite_id, data.item.invite_jobs_id)", 
                :icon="checkIcon"
              )
              | {{isInvited(data.item.invite_id, data.item.invite_jobs_id) ? 'Invited' : 'Invite'}}
            
            button.btn.btn-secondary.btn-sm.ml-2(
              style="display: inline-block"
              :disabled='data.item.exclude_id !== null') 
              | Exclude
        .col-6
          h4(style="color: #42bff4") Experience
          .row.m-0(v-for="(e, index) in data.item.exp", :key="index" style="padding: 20px; background: white")
            .col-12.pl-0.text-left
              p.m-0(style="font-size: 14px;color: #9b9b9b") {{e.from_to}}
              p.m-0(style="font-size: 20px; font-weight: 600") {{e.company}}
              p.m-0(style="font-size: 18px; font-weight: 300") {{e.position}}
              span.small(style="color: #5d5d5d") {{e.location}} 
    
    template(v-slot:cell(essentialsPercentageCoverage)="data")
      p {{essentialsPercentageCoverage}}

    template(v-slot:cell(desirablesPercentageCoverage)="data")
      p {{desirablesPercentageCoverage}}

    template(v-slot:cell(chance-of-acceptance)="data")
      p {{Math.floor(Math.random() * 100)}}%

    template(v-slot:cell(other-offers)="data")
      p {{data.item.offers}}

    template(v-slot:cell(earliest-start-date)="data")
      b-badge(v-if="data.item.startDate < thresholdDate" variant="success")
        | NOW
      p(v-else)  
        | {{ data.item.startDate | formatDate }}
    
    //- template(v-slot:cell(source)="data")
      p {{ data.item.source }}

    template(v-slot:cell(experience)="data")
      | 0

    template(v-slot:cell(endorsments)="data")
      | 0
    
    template(v-slot:cell(action)="row")
      div.text-right
        //- button.btn.btn-success.btn-sm(
          :class="{'btn-invisible': !isInvited(row.item.invite_id, row.item.invite_jobs_id)}"
          style="display: inline-block"
          disabled
          ) 
          font-awesome-icon.mr-1(:icon="checkIcon")
          | Invited

        button.btn.btn-secondary.btn-sm.ml-2(
          @click="row.toggleDetails"
          style="display: inline-block"
        ) Show CV

</template>
<script>
import dayjs from 'dayjs'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'TableCandidates',

    props: {
        job: {
            type: Object,
            required: true,
        },

        candidates: {
            type: Array,
            required: true,
            default: () => [],
        },
    },

    computed: {
        sortedCandidates: (vm) => vm.sortCandidates(vm.candidates),
        thresholdDate: (vm) => new Date(2019, 8, 18).toISOString(),
        essentialsPercentageCoverage: function () {
            const { essentialsPercentage } = data.item
            return essentialsPercentage ? essentialsPercentage.toFixed(0) + '%' : '-'
        },
        desirablesPercentageCoverage: function () {
            const { skillsNicePercentage } = data.item
            return skillsNicePercentage ? skillsNicePercentage.toFixed(0) + '%' : '-'
        },
        crossIcon: () => faTimes,
        checkIcon: () => faCheck,
    },

    filters: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY')
        },
    },

    data() {
        return {
            table: {
                busy: false,
            },

            fields: [
                { key: 'name' },
                { key: 'essentials' },
                { key: 'desirables' },
                { key: 'other-offers' },
                { key: 'earliest-start-date', label: 'Earliest start date' },
                // { key: 'source' },
                { key: 'experience' },
                { key: 'endorsments' },
                { key: 'action', thStyle: { 'text-align': 'right' } },
            ],
        }
    },

    methods: {
        sortCandidates() {
            return [...this.candidates].map(this.addSkillsPercentageCoverage).sort(this.compare)
        },

        addSkillsPercentageCoverage(candidate) {
            candidate.essentialsPercentage = this.calculateSkillsPercentageCoverage(
                candidate,
                'skills'
            )
            candidate.skillsNicePercentage = this.calculateSkillsPercentageCoverage(
                candidate,
                'skills_nice'
            )
            return candidate
        },

        calculateSkillsPercentageCoverage({ skills }, skillsType) {
            const userSkills = skills
                .map((obj) => obj.toLowerCase())
                .map(this.removeLastCharIfNumber)

            const snakeToCamel = (name) => name.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            const formattedSkillsFromJob =
                this.job[skillsType] || this.job[snakeToCamel(skillsType)] || [] //sometimes there are no skills_nice
            const flattenedSkills = formattedSkillsFromJob
                .map((obj) => obj.name)
                .map(this.removeLastCharIfNumber)

            const compareJobSkillsToUserSkills = (jobSkill) =>
                userSkills.some((x) => {
                    // some skills require clean up before comparing
                    jobSkill = jobSkill.replace('css3', 'css')
                    jobSkill = jobSkill.replace('c++', 'c\\+\\+')
                    x = x.replace('c++', 'c\\+\\+')

                    return RegExp(jobSkill, 'i').test(x)
                })

            let matches = flattenedSkills.filter(compareJobSkillsToUserSkills)

            return skillsType === 'skills'
                ? (matches.length / flattenedSkills.length) * 100
                : matches.length > 0
                ? (matches.length / flattenedSkills.length) * 100
                : 0
        },

        removeLastCharIfNumber(str) {
            return isNaN(str.charAt(str.length - 1)) ? str : str.slice(0, str.length - 1)
        },

        compare(a, b) {
            let comparison = 0
            if (a.essentialsPercentage < b.essentialsPercentage) {
                comparison = 1
            } else if (a.essentialsPercentage > b.essentialsPercentage) {
                comparison = -1
            } else {
                if (a.skillsNicePercentage < b.skillsNicePercentage) {
                    comparison = 1
                } else if (a.skillsNicePercentage > b.skillsNicePercentage) {
                    comparison = -1
                } else {
                    if (a.startDate > b.startDate) {
                        comparison = 1
                    } else if (a.startDate < b.startDate) {
                        comparison = -1
                    }
                }
            }
            return comparison
        },

        inviteCandidate(candidateId) {
            this.table.busy = true
            this.$store.state.backend
                .post('/invite', {
                    jobs_id: this.job.id,
                    users_id: candidateId,
                })
                .then((res) => {
                    const assignInviteDataToUser = (user) => {
                        if (user.id === candidateId) {
                            ;(user.invite_id = res.data.id),
                                (user.invite_jobs_id = res.data.jobs_id)
                        }
                        return user
                    }

                    this.initialSearchCandidates.map(assignInviteDataToUser)
                    this.candidates.map(assignInviteDataToUser)
                })
                .catch((err) => alert(err))
                .finally(() => (this.table.busy = false))
        },

        isInvited(inviteId, inviteJobsId) {
            return inviteId !== null && inviteJobsId === this.job.id
        },
    },
}
</script>
<style lang="sass" scoped>
.btn-invisible
  visibility: hidden
</style>
