<template>
    <div class="card">
        <div class="card-body">
            <div v-if="candidatesCareer.length > 0">
                <h4>Career site</h4>
                <div class="card mb-4">
                    <div class="card-body">
                        <TableCandidates :job="job" :candidates="candidatesCareer" />
                    </div>
                </div>
            </div>

            <div v-else>
                <h4>
                    Career site:<br />
                    <span class="text-muted">0 candidates</span>
                </h4>
                <hr />
            </div>

            <div v-if="candidatesWhiteLabel.length > 0">
                <h4>Company referrals</h4>
                <div class="card mb-4">
                    <div class="card-body">
                        <TableCandidatesRefaired
                            :job="job"
                            :candidates="candidatesWhiteLabel"
                            :traits="candidatesWhiteLabelTraits"
                        />
                    </div>
                </div>
            </div>

            <div v-else>
                <h4>
                    Company referrals:<br />
                    <span class="text-muted">{{ candidatesWhiteLabel.length }} candidates</span>
                </h4>
                <hr />
            </div>

            <div v-if="candidatesRefairme.length > 0">
                <h4>Refair.me candidates</h4>
                <small class="text-muted">
                    First 5 candidates of {{ candidatesRefairmeLength }}
                </small>
                <div class="card mb-4">
                    <div class="card-body">
                        <TableCandidates :job="job" :candidates="candidatesRefairme" />
                    </div>
                </div>
            </div>

            <div v-else>
                <h4>
                    Refair.me candidates:<br />
                    <span class="text-muted">0 candidates</span>
                </h4>
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { TableCandidates, TableCandidatesRefaired } from './components'

export default {
    name: 'JobBuilderCandidates',

    props: {
        job: {
            type: Object,
            required: true,
        },
        id: {
            type: Number,
            required: false,
        },
    },

    components: {
        TableCandidates,
        TableCandidatesRefaired,
    },

    mounted() {
        this.searchForCandidatesForThisJob(true)
    },

    activated() {
        if (this.flattenedSkills.length >= 1) {
            this.searchForCandidatesForThisJob(true)
        } else {
            this.candidatesCareer = []
            this.candidatesWhiteLabel = []
            this.candidatesRefairme = []
            this.candidatesRefairmeLength = 0
        }
    },

    computed: {
        flattenedSkills: (vm) => vm.flattenSkills(vm.job.skills),

        selectedSkills: (vm) =>
            vm.flattenedSkills.filter((skill) => !vm.excludedSkills.includes(skill)),

        crossIcon: () => faTimes,
    },

    data() {
        return {
            initialSearchCandidates: [],
            candidates: [],
            numberOfCandidates: '',
            excludedSkills: [],

            candidatesCareer: [],
            candidatesWhiteLabel: [],
            candidatesWhiteLabelTraits: [],
            candidatesRefairme: [],
            candidatesRefairmeLength: 0,

            isExpanded: this.expanded,
            tooltipText: '',
        }
    },

    methods: {
        flattenSkills(skills) {
            return skills.map((obj) => obj.name).map(this.removeLastCharIfNumber)
        },

        removeLastCharIfNumber(str) {
            return isNaN(str.charAt(str.length - 1)) ? str : str.slice(0, str.length - 1)
        },

        searchForCandidatesForThisJob(initialSearch = false) {
            const setInitialCandidates = (res) => {
                this.initialSearchCandidates = res.data.exact.data
                this.candidatesCareer = res.data.exact.bySource.career
                this.candidatesRefairme = res.data.exact.bySource.refairme
                this.candidatesRefairmeLength = res.data.exact.refairmeLength
            }

            const setCandidates = (res) => {
                const initialSearchIds = this.initialSearchCandidates.map((x) => x.id)

                this.candidates = res.data.exact.data.filter(
                    (obj) => !initialSearchIds.includes(obj.id)
                )
                this.numberOfCandidates = res.data.exact.length
            }

            const url =
                `/recruiter/candidatesForJob` +
                `?skills=${this.selectedSkills.join().replace('++', '%2B%2B')}` +
                `&id=${this.job.id}`

            this.$store.state.backend
                .get(url)
                .then((res) => (initialSearch ? setInitialCandidates(res) : setCandidates(res)))
                .catch((err) => alert(err))

            const referralsUrl = `/recruiter/referrals` + `?id=${this.job.id}`
            this.$store.state.backend
                .get(referralsUrl)
                .then((res) => {
                    this.candidatesWhiteLabel = res.data.referrals
                    this.candidatesWhiteLabelTraits = res.data.traits
                })
                .catch((err) => alert(err))
        },

        // all the functions below are used only when keywords excluding is used
        isExcluded(skill) {
            return this.excludedSkills.indexOf(skill) != -1
        },

        hideIcon(skill) {
            this.$refs[skill][0].childNodes[1].classList.add('d-none')
        },

        showIcon(skill) {
            const showIcon = () => this.$refs[skill][0].childNodes[1].classList.remove('d-none')

            if (!this.isExcluded(skill)) {
                showIcon()
            }
        },

        toggleSkill(skill) {
            const index = this.excludedSkills.indexOf(skill)

            index == -1
                ? this.excludedSkills.push(skill)
                : (this.excludedSkills = [
                      ...this.excludedSkills.slice(0, index),
                      ...this.excludedSkills.slice(index + 1),
                  ])

            this.isExcluded(skill) ? this.hideIcon(skill) : this.showIcon(skill)

            this.searchForCandidatesForThisJob()
        },

        searchForCandidatesForThisJobExcludingOneSkill(skill) {
            this.$root.$emit('bv::hide::tooltip')

            this.tooltipText = 'Loading...'

            const index = this.selectedSkills.indexOf(skill)

            let temporarlyFilteredSkills = []
            if (index == -1) {
                temporarlyFilteredSkills = [...this.selectedSkills, skill]
            } else {
                temporarlyFilteredSkills = [
                    ...this.selectedSkills.slice(0, index),
                    ...this.selectedSkills.slice(index + 1),
                ]
            }

            const url =
                `/recruiter/candidatesForJob` +
                `?skills=${temporarlyFilteredSkills.join()}` +
                `&id=${this.job.id}`

            this.$store.state.backend
                .get(url)
                .then((res) => {
                    const searchDifference = res.data.exact.length - this.numberOfCandidates
                    this.tooltipText = `${Math.abs(searchDifference)} ${
                        searchDifference < 0 ? 'less candidates' : 'more candidates'
                    }`
                })
                .catch((err) => alert(err))
        },
    },
}
</script>
<style lang="sass" scoped>
.active-skill
  background: green
  color: white
  &:hover
    background: red

  .excluded-skill
    background: white
    color: black
    border-color: black
    text-decoration: line-through

  .btn-invisible
    visibility: hidden

  .red-color
    color: red
</style>
