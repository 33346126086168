<template>
    <div style="padding: 20px; background: white">
        <Multiselect
            v-model="selectedDepartment"
            track-by="name"
            label="name"
            placeholder="Select or search for project"
            :options="options"
            :searchable="true"
        />

        <h4 class="mt-2">Jobs within selected project:</h4>

        <p class="text-muted" v-if="jobs.length === 0">
            (please select project to see jobs or
            <RouterLink :to="{ name: ROUTE.DASHBOARD_JOB_ADD }">click here</RouterLink
            ><span> to create new one)</span>
        </p>

        <div v-for="job in jobs" :key="job.id">
            <BaseJobListItem :job="job" />
            <JobBuilderCandidates :job="job" :id="job.id" />
            <hr />
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { ROUTE } from '@/enums'
import { BaseJobListItem } from '@/components'
import { JobBuilderCandidates } from '@/components/JobBuilderCandidates'

export default {
    name: 'InviteCandidates',

    components: {
        Multiselect,
        BaseJobListItem,
        JobBuilderCandidates,
    },

    created() {
        this.fetchDepartmentsList()
    },

    watch: {
        selectedDepartment(val, oldVal) {
            if (val) {
                this.$store.state.backend
                    .get(`/getjobs/byProject/${val.id}`)
                    .then((res) => (this.jobs = res.data))
                    .catch((error) => alert(error))
            } else {
                this.jobs = []
            }
        },
    },

    data() {
        return {
            ROUTE,
            selectedDepartment: '',
            options: [],
            jobs: [],
        }
    },

    methods: {
        fetchDepartmentsList() {
            this.$store.state.backend
                .get('/recruiter/departments')
                .then((res) => {
                    this.options = res.data
                })
                .catch((error) => alert(error))
        },
    },
}
</script>
