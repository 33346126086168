<template lang="pug">
  div
    b-table(
        :items="sortedCandidates"
        empty-text="No candidates refaired"
        :fields="fields"
        show-empty
        hover
        )

        template(v-slot:head(trait1)="data")
            font-awesome-icon(:icon="iconThumbsUp" v-b-tooltip="'Quality of the output (0 - complete trash, 100 - pure gold)'")
        
        template(v-slot:cell(trait1)="data")
            span(v-if="data.value < 35", style="color: red; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 35 && data.value < 75", style="color: orange; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 75", style="color: green; font-size: 18px") {{ data.value }}     
            b-progress(:max="100", v-if="data.value")
                b-progress-bar.blue(:value="data.value")
        
        template(v-slot:head(trait2)="data")
            font-awesome-icon(:icon="iconCheckDouble" v-b-tooltip="'Gets shit done (0 - only sees obstacles, 100 - always delivers quality on time)'")
            
        template(v-slot:cell(trait2)="data")
            span(v-if="data.value < 35", style="color: red; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 35 && data.value < 75", style="color: orange; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 75", style="color: green; font-size: 18px") {{ data.value }}     
            b-progress(:max="100", v-if="data.value")
                b-progress-bar.blue(:value="data.value")

        template(v-slot:head(trait3)="data")
            font-awesome-icon(:icon="iconPeopleCarry" v-b-tooltip="'Collaboration with others (0 - only sees obstacles, 100 - always delivers quality on time)'")
            
        template(v-slot:cell(trait3)="data")
            span(v-if="data.value < 35", style="color: red; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 35 && data.value < 75", style="color: orange; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 75", style="color: green; font-size: 18px") {{ data.value }}     
            b-progress(:max="100", v-if="data.value")
                b-progress-bar.blue(:value="data.value")

        template(v-slot:head(trait4)="data")
            font-awesome-icon(:icon="iconDizzy" v-b-tooltip="'Adapt to uncertainty (0 - loves structure and certainty, 100 - thrives on change and adapts)'")
            
        template(v-slot:cell(trait4)="data")
            span(v-if="data.value < 35", style="color: red; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 35 && data.value < 75", style="color: orange; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 75", style="color: green; font-size: 18px") {{ data.value }}   
            b-progress(:max="100", v-if="data.value")
                b-progress-bar.blue(:value="data.value")  

        template(v-slot:head(trait5)="data")
            font-awesome-icon(:icon="iconHandshake" v-b-tooltip="'How happy would you be working with them? (0 - if it was last job on earth, 100 - would drop everything to work with them)'")
            
        template(v-slot:cell(trait5)="data")
            span(v-if="data.value < 35", style="color: red; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 35 && data.value < 75", style="color: orange; font-size: 18px") {{ data.value }}     
            span(v-if="data.value >= 75", style="color: green; font-size: 18px") {{ data.value }}   
            b-progress(:max="100", v-if="data.value")
                b-progress-bar.blue(:value="data.value")  
</template>
<script>
import dayjs from 'dayjs'
import { faCheckDouble, faPeopleCarry } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp, faDizzy, faHandshake } from '@fortawesome/free-regular-svg-icons'

export default {
    name: 'TableCandidatesRefaired',

    props: {
        job: {
            type: Object,
            required: true,
        },

        candidates: {
            type: Array,
            required: true,
            default: () => [],
        },

        traits: {
            type: Array,
            required: true,
            default: () => [],
        },
    },

    data() {
        return {
            fields: [
                { key: 'first_name', label: 'Name', tdClass: 'align-middle' },
                { key: 'referrer', tdClass: 'align-middle' },
                { key: 'how_do_you_know_them', label: 'Known from', tdClass: 'align-middle' },
                { key: 'status', tdClass: 'align-middle' },
                { key: 'trait1', label: 'Trait 1', class: 'text-center' },
                { key: 'trait2', label: 'Trait 2', class: 'text-center' },
                { key: 'trait3', label: 'Trait 3', class: 'text-center' },
                { key: 'trait4', label: 'Trait 4', class: 'text-center' },
                { key: 'trait5', label: 'Trait 5', class: 'text-center' },
                { key: 'date', class: 'text-center', tdClass: 'align-middle' },
            ],
        }
    },

    computed: {
        sortedCandidates: (vm) => vm.sortCandidates(vm.candidates),

        iconThumbsUp: () => faThumbsUp,
        iconCheckDouble: () => faCheckDouble,
        iconPeopleCarry: () => faPeopleCarry,
        iconDizzy: () => faDizzy,
        iconHandshake: () => faHandshake,
    },

    methods: {
        sortCandidates() {
            return [...this.candidates].sort(this.compareDates).map(this.formatTable)
        },

        compareDates(a, b) {
            if (a.created_at < b.created_at) return 1
            return -1
        },

        formatTable(obj) {
            obj.referrer = (obj.referrer_first_name + ' ' + obj.referrer_last_name).trim()
            obj.date = dayjs(obj.created_at).format('YYYY-MM-DD')
            return obj
        },

        addTraitsAndAvg(candidate) {
            const candidatesTraits = this.traits.filter(
                (trait) => trait.referral_id == candidate.id
            )

            if (candidatesTraits.length == 0) return

            if (candidatesTraits.length > 0)
                candidate.avg_traits =
                    candidatesTraits.reduce((sum, { value }) => sum + value, 0) /
                    candidatesTraits.length

            this.addTraits(candidate, candidatesTraits)
        },

        addTraits(candidate, traits) {
            candidate.trait1 = traits[0].value
            candidate.trait2 = traits[1].value
            candidate.trait3 = traits[2].value
            candidate.trait4 = traits[3].value
            candidate.trait5 = traits[4].value
        },
    },

    mounted() {
        this.candidates.map(this.addTraitsAndAvg)
    },
}
</script>
<style lang="sass" scoped>
.btn-invisible
  visibility: hidden
</style>
